$(function() {

  $('#nav-expand').on('click', function(e){
    e.preventDefault();
    if ($(window).width() < 768) {
      $('#main-nav').slideToggle(100);
    } else {
      $('#main-nav').fadeToggle(250);
    }
  })

  if ($(document).scrollTop() > 170 && $(window).width() > 767) {
    $('body').addClass('small-nav');
  }

  $(window).resize(function() {
    if ($(window).width() < 768) {
      $('body').removeClass('small-nav');
    } else if ($(document).scrollTop() > 170) {
      $('body').addClass('small-nav');
    }
  });

  $(document).scroll(function() {
    if ($(this).scrollTop() > 170 && $(window).width() > 767) {
      $('body').addClass('small-nav');
    } else if ($(window).width() > 767) {
      $('body').removeClass('small-nav');
    }
    if ($(window).width() > 767) {
      var tops = ($(document).scrollTop() * -0.25);
      $('#banner').css({'top':tops});
    }
  });

  $('.sidebar .expand').on('click', function(e){
    e.preventDefault();
    $(this).parent('li').toggleClass('open').find('ul').slideToggle(200);
  });

});